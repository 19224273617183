
import CyanPageWrapper, {
  defineComponent,
  IonPage,
  store
} from "@/components/CyanPageWrapper.vue";
import TerritoryPicker from "@/components/TerritoryPicker.vue";
import { departamentos } from '@/modules/cyanRegions'
import unreact from "@/modules/unreact";

export default defineComponent({
  name: "DirPickMpio",
  components: {
    TerritoryPicker,
    IonPage,
    CyanPageWrapper,
  },
  computed: {
    seed() {
      return unreact((this as any).$route.params.seedPM+'') || 'SV'
    }, 

    ambito() {
			if (this.seed in departamentos) {
				return departamentos[(this as any).seed].nombre;
			}
			return '';
		},

    title() {
			if (this.ambito) {
				return 'Elegir cantón (' + this.ambito + ')';
			}
			return 'Elegir cantón';
		},
		userLevel() {
      return store.state.userData.nivel || '';
    },
		backRoute() {
			return ((this as any).userLevel != 'SV') ? '/home' : '/dirPickDepto'
		},

  },
  methods: {},
});
